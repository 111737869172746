import { useState, useContext } from "react";
import {
  addLink,
  getInfo,
} from "../../http/userApi";
import {
  titleLink,
  titleEmail,
} from "../../images/index";
import { Context } from "../../index";

import LinksList from "../LinksList";

const LinksComponent = () => {
  const {links} = useContext(Context);
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState();

  var linksCounter = 0;

  const [password, setPassword] = useState("");

  const [createLinkForm, setCreateLinkForm] = useState(false);

  const [description, setDescription] = useState("");

  /// On Delete Refresh Links
  const refreshData = async (e) => {
    await getInfo().then((data) => {
      links.setLinks(data.links);
      setCreateLinkForm(false);
      setPassword(null);
    });
  };

  ///  Link Regex
  const isValidLink = (link) => {
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return isEmail ? emailPattern.test(link) : urlPattern.test(link);
  };

  /// Create New Link
  const createLink = async (e) => {
    const tempLink = isEmail
      ? link?.includes("mailto")
        ? link
        : `mailto:${link}`
      : link?.includes("http")
      ? link
      : `http://${link}`;
    e.preventDefault();
    await addLink(title, tempLink, description, null);
    cancelCreateLink();
    await getInfo().then((data) => {
      links.setLinks(data.links);
      setCreateLinkForm(false);
      // setCounter("0");
      // {
      //   data.links.map((link, index) => {
      //     if (link.password != null) {
      //       setCounter("1");
      //     }
      //   });
      // }
      setPassword(null);
    });
  };
  /// Cancel Settings
  const cancelCreateLink = () => {
    setTitle("");
    setLink("");
    setIsEmail(false);
    setDescription(null);
    setCreateLinkForm(false);
    setIsUrlValid(false);
  };

  const handleButtonClick = (type) => {
    setIsEmail(type === "email");
    setLink("");
    setTitle("");
    setIsUrlValid(false);
  };

  const handleLinkInput = (value) => {
    setLink(value);
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(value) || value.includes("mailto:")) {
      setIsEmail(true);
      setIsUrlValid(emailPattern.test(value) && title.trim() !== "");
    } else if (value.includes("http://") || value.includes("https://")) {
      setIsEmail(false);
      setIsUrlValid(urlPattern.test(value) && title.trim() !== "");
    } else {
      setIsUrlValid(isValidLink(value) && title.trim() !== "");
    }
  };

  const handleTitleChange = (e) => {
    const value = e.target.value.trim();
    console.log("handleTitleChange", value);
    setTitle(value);
    setIsUrlValid(isValidLink(link, isEmail) && value.trim() !== "");
  };

  return (
    <>
      {links.links.map((item, index) => {
        if (item.password == null) {
          linksCounter = linksCounter + 1;
        }
      })}
      <div className="flex flex-col">
        <form
          className={`
                      ${
                        createLinkForm
                          ? "w-full p-2.5 rounded flex flex-col justify-between border"
                          : "hidden"
                      }
                      border-[#CCEDF8]
                      `}
          onSubmit={createLink}
        >
          <div className="flex justify-between">
            <input
              type="text"
              className="bg-transparent text-[#645F5B] font-semibold outline-0 w-full text-[14px] dark:text-[#FFFFFF]"
              onChange={handleTitleChange}
              value={title}
              placeholder="Title"
            />
          </div>
          <input
            placeholder="Description (optional)"
            className="outline-0 mt-[5px] mb-[15px] text-base text-[#645F5B] bg-transparent dark:text-[#fff]"
            maxLength={50}
            value={description ? description : ""}
            onChange={(e) => setDescription(e.target.value)}
          />
          <input
            type="text"
            className="bg-[#D6D6D633] px-2 mt-1.5 mb-[10px] text-[#645F5B] text-base outline-0 text-[14px] dark:text-[#ffffff] dark:border-[#575350] h-[35px] rounded border focus:border-[#0A85D1] active:border-[#0A85D1]"
            // onChange={(e) => setLink(e.target.value)}
            onChange={(e) => handleLinkInput(e.target.value)}
            value={link}
            placeholder={isEmail ? "Email address" : "Link URL"}
          />
          <div className="flex justify-start space-x-2 mt-2">
            <button
              type="submit"
              className={
                isUrlValid
                  ? "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#5FC650] text-[#5FC650] font-semibold cursor-pointer text-[10px] radius rounded-[5px]"
                  : "h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] text-[#645F5B] bg-[#EAEAEA] font-semibold cursor-pointer text-[10px] radius rounded-[5px] dark:bg-[#575350] dark:border-[#575350] dark:text-[#FFFFFF]"
              }
              disabled={!isUrlValid}
            >
              <p className="">Save</p>
            </button>
            <span
              className="h-[25px] w-[49px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] text-[#645F5B] font-semibold cursor-pointer text-[10px] radius rounded-[5px] dark:text-[#FFFFFF] dark:border-[#575350]"
              onClick={() => cancelCreateLink()}
            >
              Cancel
            </span>
          </div>

          <div className="flex justify-between mt-[20px]">
            {/* Left Container */}
            <div className="flex space-x-2">
              <button
                className={`flex h-[25px] w-[25px] p-[5px] border ${
                  !isEmail ? "bg-[#0A85D1]" : "border-[#0A85D1]"
                } rounded justify-center items-center`}
                type="button"
                onClick={() => handleButtonClick("link")}
              >
                <img
                  src={titleLink}
                  height={15}
                  width={15}
                  alt="link"
                  style={{
                    filter: !isEmail ? "invert(1) brightness(10)" : "none",
                  }}
                />
              </button>
              <button
                className={`flex h-[25px] w-[25px] p-[5px] border ${
                  isEmail ? "bg-[#0A85D1]" : "border-[#0A85D1]"
                } rounded justify-center items-center`}
                type="button"
                onClick={() => handleButtonClick("email")}
              >
                <img
                  src={titleEmail}
                  height={15}
                  width={15}
                  alt="link"
                  style={{
                    filter: isEmail ? "invert(1) brightness(10)" : "none",
                  }}
                />
              </button>
            </div>
          </div>
        </form>

        {!createLinkForm && (
          <div
            className={`w-full p-[10px] border rounded flex justify-between h-[62px]   ${
              linksCounter >= 10 ? "border-[#E1655C]" : "border-[#F4F3F3]"
            }`}
          >
            {linksCounter >= 10 && (
              <span className="text-[#E1655C] text-[12px]">
                The maximum number of links is 10
              </span>
            )}
            <>
              <button
                onClick={() =>
                  linksCounter < 10 ? setCreateLinkForm(true) : ""
                }
                className="ml-auto mr-0 h-[25px] w-[40px] flex justify-center items-center rounded bg-[#EAEAEA] font-semibold text-[#589ED5] text-[10px]"
              >
                Add
              </button>
            </>
          </div>
        )}
      </div>

      <LinksList
        onDelete={() => {
          refreshData();
        }}
        showProtectedList={false}
      />
    </>
  );
};

export default LinksComponent;
