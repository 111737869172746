import React, { useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {blueDash} from "../../images/index";

const ProfileSection = ({ id, content, onRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id ,
      transition : {duration: 150, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',    
      resize:'none'
    }});
  // const style = {
  //   transform: CSS.Transform.toString(transform),
  //   // transform: CSS.Translate.toString(transform),
  //   transition,  
  //   maxWidth: 500,
  // };
  const style = useMemo(() => {
    return {
        ...(isDragging ? { transform: CSS.Translate.toString(transform) } : null),
        transition,
    }
}, [isDragging, transform, transition])
  return (
    <div  className="mb-[45px]">
    <div
      ref={setNodeRef}
      style={style}
      className="bg-[#BCBCBC66] -m-[6px] p-[6px] opacity-[0.5]"
    >
    <div className="relative ">
      <span
        className="absolute top-[-15px] left-[-15px] bg-[#ffffff] rounded-full h-[30px] w-[30px] inline-flex items-center justify-center border-[1px] border-[#ffffff] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] z-[999]"
        onClick={(e) => {
          e.stopPropagation();
          console.log("remove");
          onRemove(id);
        }}
      >
        <img src={blueDash} alt="" />
      </span>
      <div className="profile-section" {...attributes} {...listeners}>
        <div>{content}</div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default ProfileSection;
