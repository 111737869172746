import { observer } from "mobx-react-lite";
import { useState, useContext } from "react";
import { Context } from "../index";
import LinkItem from "./LinkItem";
import ProtectedLink from "./ProtectedLink";
import LinkItemProtected from "./LinkItemProtected";



  const LinksList = observer(({onDelete , showProtectedList, onlyView}) => {
  const { user, links } = useContext(Context);
  
  return (
    <>
    {/* { protectedLink && !user.user.id &&( */}
    {/* { protectedLink && !user.user.id &&(
       <ProtectedLink
       protectedLink = {protectedLink}
       />
    )} */}
    <div className="flex flex-col justify-between space-y-2.5 mt-[12px]">
      { showProtectedList && links.links.filter((link)=>link.password != null).map((link, i) => (
        <>
          { link.password != null && (!user.user.id  || ( user.user.id != link.userId) || onlyView) &&(
            <ProtectedLink
            protectedLink = {link}
            />
          )}
          { link.password != null  && user.user.id &&  ( user.user.id == link.userId) && !onlyView && (
          <LinkItemProtected
                    key={link.id}
                    onDelete={() => {
                      links.removeLink(link.id);
                      onDelete();
                    }}
                    onEdit={(id, newTitle, newLink, desc,password) => {
                      // links.setLinks(data);
                      links.editLink(id, newTitle, newLink, desc,password);
                    }}
                    userId={user.user.id}
                    link={link}
                    index={i}
                    onSwap={(e) => {
                      e.preventDefault();
                      links.swapLink(i, i - 1);
                    }}
                  />
                )}
       
          {/* {!protectedLink && link.password != null ? setProtectedLink(link) : ""} */}
        </>
        
      ))}
      { !showProtectedList &&  links.links.filter((link)=>link.password == null).map((link, i) => (
        <>
          {/* { link.password != null  && !user.user.id &&(
            <ProtectedLink
            protectedLink = {link}
            />
          )} */}
        
        <LinkItem
          key={link.id}
          onDelete={() => {
            links.removeLink(link.id);
            onDelete();
          }}
          onEdit={(id, newTitle, newLink, desc) => {
            // links.setLinks(data);
            links.editLink(id, newTitle, newLink, desc);
          }}
          userId={user.user.id}
          link={link}
          index={i}
          onSwap={(e) => {
            e.preventDefault();
            links.swapLink(i, i - 1);
          }}
          onlyView={onlyView}
        />
          {/* {!protectedLink && link.password != null ? setProtectedLink(link) : ""} */}
        </>
        
      ))}

    </div>

    </>
  );
});

export default LinksList;
