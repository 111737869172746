import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Fishcropped1,
  Fishcropped2,
  Fishcropped3,
  Fishcropped4,
  Fishcropped5,
  Fishcropped6,
  Fishcropped7,
  previewIcon,
  shareIcon,
  Arrangements,
  ComponentsIcon,
} from "../images/index";

import { Context } from "../index";
import QuestionAndAnswer from "../components/Base/QuestionAndAnswer";
import LinksComponent from "../components/Base/LinksComponent";
import ProtectedLinkComponent from "../components/Base/ProtectedLinkComponent";
import Showcase from "../components/Base/Showcase";
import { toast } from "react-toastify";
import TextComponent from "../components/Base/TextComponent";
import ImageComponent from "../components/Base/ImageComponent";
import { useNavigate } from "react-router-dom";
import { SEARCH_USER } from "../utils/consts";
import { COMPONENT_ID } from "../utils/index";
import { showToast } from "../utils/BannerPopup";

const Toolbar = ({ addSection, BioComponent, setArrangement, arrangement }) => {
  const { user } = useContext(Context);
  const isAuthPremium = user.user.is_premium;
  const [showOptions, setShowOptions] = useState(false);
  const modalRef = useRef(null);
  const handleToggle = () => {
    setShowOptions(!showOptions);
  };

  // states and method of banner
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const showBanner = () => {
    setIsBannerVisible(true);
    setTimeout(() => {
      setIsBannerVisible(false);
    }, 3000);
  };

  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    if (showOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      setShowOptions(false);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions]);

  const PreviewUser = () => {
    var userView = user.user.username;
    navigate(SEARCH_USER + userView);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div ref={modalRef}>
          {showOptions && (
            <div className="w-[220px] bg-[#645F5BE5] p-2 rounded-lg mb-4">
              <div className="mb-2">
                <div className="text-white mb-1 text-sm ">Free</div>
                <div className="grid grid-cols-4 gap-2">
                  <div
                    onClick={() =>
                      addSection(COMPONENT_ID.BIO_COMPONENT, <BioComponent />)
                    }
                  >
                    <div className="w-[40px] h-[40px] p-[12px_8px] rounded gap-[10px] bg-[#BCBCBC]">
                      <img
                        src={Fishcropped1}
                        alt="Brief"
                        className="w-[24px] h-[12px] rounded-md items-center"
                      />
                    </div>
                    <p className="text-[10px] text-[#fff] font-medium text-center leading-[12px] mt-[2px]">
                      Brief
                    </p>
                  </div>

                  <div
                    onClick={() =>
                      addSection(
                        COMPONENT_ID.QA_COMPONENT,
                        <QuestionAndAnswer />
                      )
                    }
                  >
                    <div className="w-[40px] h-[40px] p-[12px_8px] rounded gap-[10px] bg-[#BCBCBC]">
                      <img
                        src={Fishcropped2}
                        alt="Expand"
                        className="w-[24px] h-[12px] rounded-md items-center"
                      />
                    </div>
                    <p className="text-[10px] text-[#fff] font-medium text-center leading-[12px] mt-[2px]">
                      Expand
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      addSection(
                        COMPONENT_ID.LINK_COMPONENT,
                        <LinksComponent />
                      )
                    }
                  >
                    <div className="w-[40px] h-[40px] p-[12px_8px] rounded gap-[10px] bg-[#BCBCBC]">
                      <img
                        src={Fishcropped3}
                        alt="Link"
                        className="w-[24px] h-[12px] rounded-md items-center"
                      />
                    </div>
                    <p className="text-[10px] text-[#fff] font-medium text-center leading-[12px] mt-[2px]">
                      Link
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      addSection(COMPONENT_ID.TEXT_COMPONENT, <TextComponent />)
                    }
                  >
                    <div className="w-[40px] h-[40px] p-[12px_8px] rounded gap-[10px] bg-[#BCBCBC]">
                      <img
                        src={Fishcropped4}
                        alt="Text"
                        className="w-[24px] h-[12px] rounded-md items-center"
                      />
                    </div>
                    <p className="text-[10px] text-[#fff] font-medium text-center leading-[12px] mt-[2px]">
                      Text
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-white mb-1 text-sm ">Plus</div>

                <div className="grid grid-cols-4 gap-2">
                  <div
                    onClick={
                      isAuthPremium
                        ? () =>
                            addSection(
                              COMPONENT_ID.PROTECTED_COMPONENT,
                              <ProtectedLinkComponent />
                            )
                        : null
                    }
                  >
                    <div className="w-[40px] h-[40px] p-[12px_8px] rounded gap-[10px] bg-[#BCBCBC] mt-[2px]">
                      <img
                        src={Fishcropped5}
                        alt="Lock"
                        className="w-[24px] h-[12px] rounded-md items-center"
                      />
                    </div>
                    <p className="text-[10px] text-[#fff] font-medium text-center leading-[12px] mt-[2px]">
                      Lock
                    </p>
                  </div>
                  <div
                    onClick={
                      isAuthPremium
                        ? () =>
                            addSection(
                              COMPONENT_ID.SHOWCASE_COMPONENT,
                              <Showcase />
                            )
                        : null
                    }
                  >
                    <div className="w-[40px] h-[40px] p-[12px_8px] rounded gap-[10px] bg-[#BCBCBC]">
                      <img
                        src={Fishcropped6}
                        alt="Block"
                        className="w-[24px] h-[12px] rounded-md items-center"
                      />
                    </div>
                    <p className="text-[10px] text-[#fff] font-medium text-center leading-[12px] mt-[2px]">
                      Block
                    </p>
                  </div>
                  <div
                    onClick={
                      isAuthPremium
                        ? () =>
                            addSection(
                              COMPONENT_ID.IMAGE_COMPONENT,
                              <ImageComponent />
                            )
                        : null
                    }
                  >
                    <div className="w-[40px] h-[40px] p-[12px_8px] rounded gap-[10px] bg-[#BCBCBC]">
                      <img
                        src={Fishcropped7}
                        alt="Image1"
                        className="w-[24px] h-[12px] rounded-md items-center"
                      />
                    </div>
                    <p className="text-[10px] text-[#fff] font-medium text-center leading-[12px] mt-[2px]">
                      Image
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        
        <div className="flex justify-between items-center bg-[#645F5BE5] p-2 rounded-md w-[170px]">
          <div
            className={`hover-container w-[30px] h-[30px] p-[7px] rounded gap-[10px] bg-[#BCBCBC] ${
              arrangement ? "active-container" : ""
            }`}
            onClick={() => {
              setArrangement(true);
            }}
          >
            {!arrangement && <span className="hover-text">Arrangement</span>}
            <img
              src={Arrangements}
              alt="Arrangements"
              className="w-[18px] h-[18px] rounded-md"
            />
            {!arrangement && <span className="hover-dot"></span>}
          </div>

          <div
            className={`hover-container w-[30px] h-[30px] p-[7px] rounded gap-[10px] bg-[#BCBCBC] ${
              showOptions ? "active-container" : ""
            }`}
            onClick={() => {
              handleToggle();
              setArrangement(false);
            }}
          >
            {!showOptions && <span className="hover-text">Components</span>}
            <img
              src={ComponentsIcon}
              alt="ComponentsIcon"
              className="w-[18px] h-[18px] rounded-md"
            />
            {!showOptions && <span className="hover-dot"></span>}
          </div>
          <div
            className="hover-container w-[30px] h-[30px] p-[7px] rounded gap-[10px] bg-[#000000]"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://layer.page/${user?.user?.username}`
              );
              setArrangement(false);
              showToast("Layer Copied");
            }}
          >
            {!arrangement && <span className="hover-text">Share</span>}
            <img
              src={shareIcon}
              alt="shareIcon"
              className="w-[18px] h-[18px] rounded-md items-center"
            />
            {!arrangement && <span className="hover-dot"></span>}
          </div>

          <div
            className="hover-container w-[30px] h-[30px] p-[7px] rounded gap-[10px] bg-[#000000]"
            onClick={() => {
              setArrangement(false);
              PreviewUser();
            }}
          >
            <span className="hover-text">Preview</span>
            <img
              src={previewIcon}
              alt="previewIcon"
              className="w-[18px] h-[18px] rounded-md"
            />
            <span className="hover-dot"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Toolbar;
