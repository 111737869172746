import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getLayerInfo } from "../../http/userApi";
import {
  addQA,
  selectedFaqs,
  deleteQueationAnswer,
  editItem,
} from "../../http/messageApi";
import { Context } from "../../index";
import { check } from "../../images/index";

const QuestionAndAnswer = ({ selectedUsername, onlyView }) => {
  const { user } = useContext(Context);

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [createLinkForm, setCreateLinkForm] = useState(false);
  const [userId, setUserId] = useState(null);
  const [authUserId, setAuthUserId] = useState(user.user.id);
  const isAuthPremium = user.user.is_premium;

  const [loading, setLoading] = useState(true);

  const [faqAll, setFaqAll] = useState([]);

  useEffect(() => {
    getLayerInfo(selectedUsername ? selectedUsername : user.user.username).then(
      (data) => {
        setUserId(data.id);
        selectedFaqs(data.id).then((data) => {
          if (data) {
            setFaqAll(data);
          }
        });
      }
    );
  }, []);

  const addQuestionAnswer = async (e) => {
    e.preventDefault();
    const data = await addQA(question, answer, userId);
    if (data) {
      selectedFaqs(userId)
        .then((data) => {
          if (data) {
            setFaqAll(data);
          }
        })
        .finally(() => setLoading(false));
    }
    // setFaqAll(faqAll.concat(data.data));
    setQuestion("");
    setAnswer("");
    setCreateLinkForm(false);
  };

  const cancelCreateLink = () => {
    setQuestion(null);
    setAnswer(null);
    setCreateLinkForm(false);
  };

  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const isFormValid =
    (question || "").trim() !== "" && (answer || "").trim() !== "";

  return (
    <div className="">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card--">
              <div className="card-body--">
                {user.isAuth &&
                  authUserId == userId &&
                  !onlyView &&
                  faqAll.length < 10 && (
                    <div className="rc-accordion-card ">
                      <div className="flex flex-col mt-0">
                        <form
                          className={
                            createLinkForm
                              ? "w-full p-2.5 rounded flex flex-col justify-between border dark:border-[#575350] mb-[12px]"
                              : "hidden"
                          }
                          onSubmit={addQuestionAnswer}
                        >
                          <div className="flex justify-between">
                            <input
                              type="text"
                              className="bg-transparent text-[#645F5B] font-semibold outline-0 w-full text-[14px] dark:text-[#FFFFFF]"
                              onChange={(e) => setQuestion(e.target.value)}
                              value={question ? question : ""}
                              placeholder="[Q&A title]"
                            />
                          </div>

                          <textarea
                            placeholder="[Q&A description (max. 150 characters)]"
                            className="outline-0 mt-[5px] mb-[15px] bg-transparent resize-none text-base text-[#645F5B] dark:text-white"
                            maxLength={150}
                            value={answer ? answer : ""}
                            onChange={(e) => setAnswer(e.target.value)}
                          ></textarea>

                          <div className="flex justify-between mt-2">
                            <button
                              type="submit"
                              className={
                                isFormValid
                                  ? `h-[30px] w-[47px] flex justify-center items-center space-x-1.5 border border-[#CCF0E4] text-[#00B27A]  cursor-pointer text-sm  font-regular rounded`
                                  : `h-[30px] w-[47px] flex justify-center items-center space-x-1.5 border border-[#FAF4EE] text-[#645F5B] bg-[#EAEAEA]  cursor-pointer text-sm  font-regular rounded`
                              }
                              disabled={!isFormValid}
                            >
                              <span>Save</span>
                            </button>
                            <span
                              className="h-[30px] w-[65px] flex justify-center items-center space-x-1.5 border-[1px] border-[#E0DFDE] dark:border-[#575350] text-[#645F5B]  dark:text-[#ffffff]  rounded text-sm  font-regular cursor-pointer"
                              onClick={() => cancelCreateLink()}
                            >
                              Cancel
                            </span>
                          </div>
                        </form>
                        {!createLinkForm && (
                          <div className="w-full p-[10px]  flex items-center justify-between">
                            <h5 className="rc-accordion-title dark:text-[#FFFFFF]">
                              Q&A
                            </h5>
                            <button
                              onClick={() => setCreateLinkForm(true)}
                              className="ml-auto mr-0 h-[25px] w-[40px] flex justify-center items-center rounded bg-[#EAEAEA] font-semibold text-[#589ED5] text-[10px]"
                            >
                              Add
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                {faqAll.map((faq, index) => {
                  return (
                    <AccordionItem
                      key={faq.id}
                      active={active}
                      handleToggle={handleToggle}
                      faq={faq}
                      userId={userId}
                      authUserId={authUserId}
                      user={user}
                      isAuthPremium={isAuthPremium}
                      setFaqAll={setFaqAll}
                      onlyView={onlyView}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const AccordionItem = (props) => {
  const contentEl = useRef();
  const {
    handleToggle,
    active,
    faq,
    userId,
    authUserId,
    user,
    setFaqAll,
    onlyView,
  } = props;
  const { question, id, answer } = faq;
  const [tempQuestion, setTempQuestion] = useState(question);
  const [tempAnswer, setTempAnswer] = useState(answer);
  const [savedQuestion, setSavedQuestion] = useState(question);
  const [savedAnswer, setSavedAnswer] = useState(answer);
  const canEdit = authUserId == userId && !onlyView;
  const removeQuestionAnswer = async (item) => {
    const data = await deleteQueationAnswer(item);
    selectedFaqs(userId)
      .then((data) => {
        if (data) {
          setFaqAll(data);
        }
      })
  };

  const modifyLink = async (e) => {
    e.preventDefault();
    const data = await editItem(tempQuestion, tempAnswer, authUserId, id);
    setSavedQuestion(tempQuestion);
    setSavedAnswer(tempAnswer);
  };

  // const modifyLink = async (e) => {
  //   e.preventDefault();
  //   if (!tempQuestion.trim() || !tempAnswer.trim()) {
  //     showToast('question and answer can not be empty');
  //     // Revert to last saved values
  //     setTempQuestion(savedQuestion);
  //     setTempAnswer(savedAnswer);
  //     return;
  //   }
  //   try {
  //     const data = await editItem(tempQuestion, tempAnswer, authUserId, id);
  //     // Update saved values after successful save
  //     setSavedQuestion(tempQuestion);
  //     setSavedAnswer(tempAnswer);
  //   } catch (error) {
  //     console.error('An error occurred while saving. Please try again.');
  //   }
  // };
  
  const relodAll = async (item) => {
    selectedFaqs(userId)
      .then((data) => {
        if (data) {
          setFaqAll(data);
        }
      }) 
      setTempQuestion(savedQuestion);
      setTempAnswer(savedAnswer);
  };

  return (
    <>
      <div
        className={`c-accordion-card ${user.isAuth && canEdit ? "auth" : ""}`}
      >
        <form className="" onSubmit={modifyLink}>
          <div
            className={`rc-accordion-header ${
              user.isAuth && canEdit ? "editable" : ""
            }`}
          >
            <div
              className={`rc-accordion-toggle p-[10px] ${
                active === id ? "active d-none" : ""
              }`}
              onClick={() => handleToggle(id)}
            >
              <h5
                className={`rc-accordion-title dark:text-[#FFFFFF] ${
                  active === id ? "active" : ""
                }`}
              >
                {tempQuestion}
              </h5>                                                                                                                                                                                            
              <i className="fa fa-chevron-down rc-accordion-icon"></i>
            </div>
            {user.isAuth && canEdit && (
              <div
                className={`rc-accordion-toggle p-[10px] ${
                  active === id ? "active" : "d-none"
                }`}
              >
                <input
                  type="text"
                  className="w-full rc-editable-title outline-0 text-[#589ed5] dark:text-[#589ed5] bg-transparent"
                  placeholder="Name"
                  onChange={(e) => {
                    setTempQuestion(e.target.value);
                  }}
                  value={tempQuestion}
                />
                <i
                  onClick={() => handleToggle(id)}
                  className="fa fa-chevron-down rc-accordion-icon"
                ></i>
              </div>
            )}
          </div>
          <div
            ref={contentEl}
            className={`rc-collapse ${active === id ? "show" : ""}`}
            style={
              active === id
                ? { height: contentEl.current.scrollHeight }
                : { height: "0px" }
            }
          >
            <div
              className={`rc-accordion-body ${
                active === id ? "active d-none" : ""
              }`}
            >
              <p className="mb-0 px-[10px] pb-[10px] dark:text-[#FFFFFF]">
                {tempAnswer}
              </p>
            </div>
            {user.isAuth && canEdit && (
              <div
                className={`rc-accordion-body ${
                  active === id ? "active" : "d-none"
                }`}
              >
                <textarea
                  value={tempAnswer}
                  placeholder="[Link description (max. 150 characters)]"
                  className="w-full outline-0 mb-0 description text-[#589ed5] bg-transparent dark:text-[#589ed5] border-b-0 dark:border-[#575350] px-[10px] resize-none"
                  maxLength={150}
                  onChange={(e) => setTempAnswer(e.target.value)}
                ></textarea>
              </div>
            )}
            {user.isAuth && canEdit && (
              <div
                className={`rc-accordion-toggle p-[10px] mb-[10px] ${
                  active === id ? "active" : ""
                }`}
                onClick={() => handleToggle(id)}
              >
                <div className="flex">
                  <button
                    type="submit"
                    className="h-[30px] w-[47px] flex justify-center items-center space-x-1.5 border border-[#CCF0E4] text-[#00B27A]  cursor-pointer text-sm  font-regular rounded"
                  >
                    <span>Save</span>
                  </button>
                </div>
                <div className="flex space-x-2">
                  <span
                    className=" h-[30px] w-[65px] flex justify-center items-center space-x-1.5 border-[1px] border-[#E0DFDE] dark:border-[#575350] text-[#645F5B]  dark:text-[#ffffff]  rounded text-sm  font-regular"
                    onClick={() => relodAll()}
                  >
                    Cancel
                  </span>
                  <button
                    style={{ height: "30px", width: "65px" }}
                    className={`btn border-[1px] border-[#F6DAD0]  text-[#D14414] rounded text-sm  font-regular`}
                    onClick={() => removeQuestionAnswer(id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default QuestionAndAnswer;
