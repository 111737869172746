export const isMobile = "(max-width: 990px)";
export const isMobile767 ="(max-width: 767px)";

export const freeUserTypeArr = [
  "Messaging",
  "Links",
  "Photo and bio",
  "Sessions, connections, updates",
  "Standard username",
];

export const paidUserTypeArr = [
  "Bookmarks",
  "Protected links",
  "Blocks",
  "Messaging permissions",
  "QR code",  
  "Premium username"
];

export function queryStringToObject(queryString) {
  const pairs = queryString.substring(1).split("&");

  var array = pairs.map((el) => {
    const parts = el.split("=");
    return parts;
  });

  return Object.fromEntries(array);
}

export function getUrlParams(url) {
  let urlParams = url.split("&");
  let params = {};

  if (urlParams.length) {
    urlParams.map((e) => {
      let objKey = e.split("=")?.[0];
      params[objKey] = e.split("=")?.[1];
    });
  }
  return params;
}

export const isEmail = (str) => {
  // Regular expression to check if string is email
  const regexExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
  return regexExp.test(str); // true
};

export const USERNAME_EXIST = 'Username already exists!';
export const PREMIUM_USERNAME="Premium username";
export const LAYER_ONLY="Layers only";
export const BOOKMARK_ONLY="Bookmarks only";
export const EVERYBODY="Everybody";
export const NOBODY="Nobody";
//const for dnd componenT

export const COMPONENT_ID={
  LINK_COMPONENT:1,
  BIO_COMPONENT:2,
  QA_COMPONENT:3,
  TEXT_COMPONENT:4,
  IMAGE_COMPONENT:5,
  PROTECTED_COMPONENT:6,
  SHOWCASE_COMPONENT:7
};