import { useCallback, useContext, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import {
    sliderArrowRight,
    sliderArrowLeft,
  } from "../images/index";
  import { Context } from "../index";
import { getShowcase } from "../http/userApi";

const ShowCaseBanner = () => {
    // const emblaRef = useRef(null);
    const { selectedUser } = useContext(Context);
    const selectedUserId = selectedUser.selectedUser.id;
    const [emblaRef, emblaApi] = useEmblaCarousel();
    const [showCases, setShowCases] = useState([]);

    const scrollPrev = useCallback(() => {
      if (emblaApi) emblaApi.scrollPrev();
    }, [emblaApi]);
    const scrollNext = useCallback(() => {
      if (emblaApi) emblaApi.scrollNext();
    }, [emblaApi]);
    useEffect(() => {
      getShowcase(selectedUserId).then((data) => {
        setShowCases(data.data);
      });

    }, [selectedUserId]);
    
    return (
      <div className="border-[#EAEAEA]">
        <div className="embla relative" ref={emblaRef}>
          <div className="embla__container">
            {showCases.map((item, index) => (
              <div
                key={index}
                className={`w-[140px] h-[100px] rounded-[5px] p-[5px] relative bg-[${
                  item.bg_color != null ? item.bg_color : "#790726"
                }] hover:bg-[#DBDBDB] active:bg-[#CCCCCC] focus:bg-[#CCCCCC] cursor-pointer embla__slide`}
              >
                <h4 className="text-[12px] h-[30px] text-[#ffffff]">
                  {item.title !== "false" ? item.title : ""}
                </h4>
                <h4 className="[h-50px] absolute text-[#ffffff] text-[14px] bottom-[5px] left-[5px] break-all">
                  {item.description !== "false" ? item.description : ""}
                </h4>
              </div>
            ))}
          </div>
          <button
            className="embla__prev absolute -translate-y-1/2 top-1/2 left-[0] z-10"
            onClick={scrollPrev}
          >
            <img
              src={sliderArrowLeft}
              className="h-[14px]"
              alt="sliderArrowLeft"
            />
          </button>
          <button
            className="embla__next absolute right-[0] -translate-y-1/2 top-1/2 z-10"
            onClick={scrollNext}
          >
            <img
              src={sliderArrowRight}
              className="h-[14px]"
              alt="sliderArrowRight"
            />
          </button>
        </div>
      </div>
    );
  };

  export default ShowCaseBanner;