import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";
import axios from "axios";

export const check = async () => {
  const { data } = await $authHost.get("api/user/check");
  localStorage.setItem("token", data.token);
  return jwt_decode(data.token);
};

export const getInfo = async () => {
  const { data } = await $authHost.get("api/user/getInfo");
  return data;
};

export const saveUserBio = async (bio) => {
  const { data } = await $authHost.post("api/user/saveBio", { bio });
  return data;
};

export const changeUserAvatar = async (info) => {
  const { data } = await $authHost.post("api/user/changeAvatar", info);
  return data;
};

export const changeUsername = async (username) => {
  const { data } = await $authHost.post("api/user/changeUsername", {
    username,
  });
  localStorage.setItem("token", data.token);
  return jwt_decode(data.token);
};

export const getLayerInfo = async (username) => {
  const { data } = await $host.get("api/user/" + username);
  return data;
};

export const addLink = async (title, link, description, password) => {
  const { data } = await $authHost.post("api/user/addLink", {
    title,
    link,
    description,
    password,
  });
  return data;
};

export const deleteLink = async (id) => {
  const { data } = await $authHost.post("api/user/deleteLink", { id });
  return data;
};

export const editLink = async (id, title, link, description, password) => {
  const { data } = await $authHost.post("api/user/editLink", {
    id,
    title,
    link,
    description,
    password,
  });
  return data;
};

/// Get Protected Link
export const getProtectedLink = async (username) => {
  const { data } = await $host.get("api/user/" + username);
  return data;
};

export const openLink = async (id, password) => {
  const { data } = await $authHost.post("api/user/openLink", {
    id,
    password,
  });
  return data;
}

/// Add Bookmark
export const addBookmark = async (userId, bookMarkId) => {
  const { data } = await $authHost.post("api/user/addBookmark",{
    userId, 
    bookMarkId
  });
  return data;
}

/// Get Saved Bookmark
export const getBookmark = async (userId, bookMarkId) => {
  const { data } = await $authHost.post("api/user/getBookmark",{
    userId, 
    bookMarkId
  });
  return data;
}

/// List Saved Bookmark
export const listBookmarks = async () => {
  const { data } = await $authHost.post("api/user/listBookmarks");
  return data;
}

export const getConnections = async () => {
  const { data } = await $authHost.get("api/user/getConnections");
  return data;
};

export const setMailing = async (status) => {
  const { data } = await $authHost.post("api/user/setMailing", { status });
  return data;
};

export const setSignedOutMailing = async (payload) => {
  const { data } = await $authHost.post(
    "api/user/setMailingSignedout",
    payload
  );
  return data;
};

export const setEmail = async (email) => {
  const { data } = await $authHost.post("api/user/setEmail", { email });
  return data;
};

// oAuth API
export const GoogleOAuth = async (email, googleId,ipAddress, address, currentTime) => {
console.log("ipAddress, address, currentTime",ipAddress, address, currentTime)
  const { data } = await $host.post("api/user/GoogleAuth", { email, googleId,ipAddress, address, currentTime });
  if(data && data.status==2){
    return data;
  }
  if(data && data.status==1){
    localStorage.setItem("token", data.token);
    data?.session_id && localStorage.setItem("session_id", data?.session_id);
    return jwt_decode(data.token);
  }
};

export const GithubOAuth = async (code,ipAddress, address, currentTime) => {
  const { data } = await $host.post("api/user/GithubAuth", { code ,ipAddress, address, currentTime});
  if(data && data.status===2){
    return data;
  }
  if(data && data.status===1){
    localStorage.setItem("token", data.token);
    data?.session_id && localStorage.setItem("session_id", data?.session_id);
    return jwt_decode(data.token);
  }
};

export const TwitterOAuth = async (payload) => {
  const { data } = await $host.post("api/user/TwitterAuth", payload);
  if(data && data.status===2){
    return data;
  }
  if(data && data.status===1){
    localStorage.setItem("token", data.token);
    data?.session_id && localStorage.setItem("session_id", data?.session_id);
    return jwt_decode(data.token);
  }
};

export const AppleOAuth = async (payload) => {
  const { data } = await $host.post("api/user/AppleAuthLogin", payload);
  if(data && data.status != 1){
    return data;
  }
  if(data && data.status==1){
    localStorage.setItem("token", data.token);
    data?.session_id && localStorage.setItem("session_id", data?.session_id);
    return jwt_decode(data.token);
  }
};

export const DiscordOAuth = async (payload) => {
  const { data } = await $host.post("api/user/DiscordAuth", payload);
  localStorage.setItem("token", data.token);
  data?.session_id && localStorage.setItem("session_id", data?.session_id);
  return jwt_decode(data.token);
};

// Connect API

export const TwitterConnect = async (payload) => {
  const { data } = await $authHost.post("api/user/TwitterConnect", payload);
  return data;
};

export const DiscordConnect = async (payload) => {
  const { data } = await $authHost.post("api/user/DiscordConnect", payload);
  return data;
};

export const AppleConnect = async (payload) => {
  const { data } = await $authHost.post("api/user/AppleConnect", payload);
  return data;
};

export const GithubConnect = async (code) => {
  const { data } = await $authHost.post("api/user/GithubConnect", { code });
  return data;
};

export const GoogleConnect = async (email, googleId) => {
  const { data } = await $authHost.post("api/user/GoogleConnect", {
    email,
    googleId,
  });
  return data;
};

// Delete API

export const deleteConnect = async (api) => {
  const { data } = await $authHost.post("api/user/deleteConnect", { api });
  return data;
};

export const deleteUser = async (user_id) => {
  const { data } = await $authHost.post("api/user/deleteUser", { user_id });
  return data;
};

export const themeSetting = async (payload) => {
  const { data } = await $authHost.post("api/user/setThemeMode", payload);
  return data;
};

export const getThemeSetting = async (user_id) => {
  const { data } = await $authHost.post("api/user/getThemeMode", { user_id });
  return data;
};

export const getTwitterToken = async () => {
  const { data } = await $host.post("api/user/TwitterToken");
  return data;
};

export const twitterThirdFlow = async (payload) => {
  const { data } = await $host.post(`api/user/TwitterAuthToken`, payload);
  return data;
};

export const getDiscordUserInfo = async (token) => {
  const { data } = await axios.get("https://discord.com/api/v10/users/@me", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const saveNotificationEmail = async (notification_email) => {
  const { data } = await $authHost.post("api/user/saveNotificationEmail", {
    notification_email,
  });
  return data;
};

export const checkUsername = async (username) => {
  const { data } = await $authHost.post("api/user/checkUsername", {
    username,
  });
  return data;
};


/// Add ShowcaseItem
export const addShowcase = async (title = null, description = null, bgColor = null) => {
  const { data } = await $authHost.post("api/user/addShowcaseItem",{
    title, 
    description,
    bgColor
  }); 
  return data;
}

/// Get ShowcaseItem
export const getShowcase = async (user_id = null) => {
  const { data } = await $authHost.post("api/user/getShowcaseItem",{ user_id }); 
  return data;
}

/// Edit ShowcaseItem
export const updateShowcaseItem = async (title = null, description = null, bgColor = null, showCaseId = null) => {
  const { data } = await $authHost.post("api/user/updateShowcaseItem",{
    title, 
    description,
    bgColor,
    showCaseId
  }); 
  return data;
}

/// Edit Bookmark
export const deleteShowCase = async ( showCaseId = null) => {
  const { data } = await $authHost.post("api/user/deleteShowCase",{
    showCaseId
  }); 
  return data;
}

/// Add QR Code
export const regenerateQrCode = async (user_id = null) => {
  const { data } = await $authHost.post("api/user/addQrCode",{ user_id }); 
  return data;
}


/// Add QR Code
export const getQrCode = async (user_id = null) => {
  const { data } = await $authHost.post("api/user/getQrCode",{ user_id }); 
  return data;
}

////// api to get otp //////////// 

export const getOtpSignin = async (user_email) => {
  const { data } = await $authHost.post("api/user/sendOtp",{ user_email }); 
  return data;
} 

////// api to verify otp ////////////

export const verifyOtpSignin = async (otp , theme="existing_user") => {
  const { data } = await $authHost.post("api/user/verifyOtp",{ 
     otp ,
     theme
  },

);
  return data;
} 


export const verifyOtpSigninWithIp = async (otp , theme="existing_user",ipAddress, address, currentTime) => {
  const  { data } = await $authHost.post("api/user/verifyOtp",{ 
     otp ,
     theme,
     ipAddress, 
     address, 
     currentTime
  }, 
);
  return data;
} 


////// api to get otp ////////////

export const getOtpThemePlain = async (user_email) => {
  const { data } = await $authHost.post("api/user/sendOtp", { user_email, theme: 'auth' });
  return data;
}

////// api to save backup email ////////////

export const postBackupEmail = async (userId, email) => {
  const { data } = await $authHost.post("api/user/saveBackupEmail",
    {
      "userId": userId,
      "email": email
    });
  return data;
}

/////// api to make user a premium user ///////

export const setPremiumUser = async (is_premium=true) => {
  // const { data } = await $authHost.post("api/user/setPremium", { is_premium });
  // return data;
}

///////// api to handle robot.txt ///////////////

export const addToignoreLayer = async (ignore_layer=false) => {
  const { data } = await $authHost.post("api/user/ignoreLayer", {ignore_layer});
  return data;
}

///////////// api to set signIn notification //////////////

export const setSignInNotification = async (when_sign_in=false) => {
  const { data } = await $authHost.post("api/user/SignInNotification", {when_sign_in});
  return data;
}

///////////// api to delete session on sign out //////////////

export const activeSession = async (session_id) => {
  const { data } = await $authHost.post("api/user/activeSession", {session_id});
  return data;
}


export const countSession = async () => {
  const { data } =  await $authHost.post("api/user/count-sessions", {});
  return data;
}

export const signOutAll= async()=>{
  const session_id =localStorage.getItem("session_id")
  const { data } = await $authHost.post("api/user/signout-all", {session_id});
  return data;
}

export const signOutSession= async()=>{
  const session_id =localStorage.getItem("session_id")
  const { data } = await $authHost.post("api/user/sign-out-session", {session_id});
  return data;
}

export const allowOneSession= async(allow_one_session)=>{
  const { data } = await $authHost.post("api/user/allow-one-sessions", {allow_one_session});
  return data;
}


/////////  Block user and list them  ///////////////////////
export const blockUser = async (contact_id,type="blocked") => {
  const { data } = await $authHost.post("api/user/blockContact",{contact_id,type});
  return data;
}

export const listBlockUser = async () => {
  const { data } = await $authHost.post("api/user/list-blocked-contacts");
  return data;
}

export const messagePermissions = async () => {
  const { data } = await $authHost.post("api/user/messaging-permissions");
  return data;
}

export const getUserIpTimeAddress = async () => {
  const { data } = await axios.get("https://ipapi.co/json/"); 
  const ipAddress=data.ip;
  const address=`${data?.region}, ${data?.country_name}`;
  const now = new Date();
  const options = { 
    hour: '2-digit', 
    minute: '2-digit', 
    day: '2-digit', 
    month: 'long', 
    year: 'numeric', 
    timeZone: 'UTC', 
    timeZoneName: 'short' 
  };
  const formatter = new Intl.DateTimeFormat('en-GB', options);
  const parts = formatter.formatToParts(now);
  
  const timePart = parts.filter(part => part.type === 'hour' || part.type === 'minute').map(part => part.value).join(':');
  const datePart = parts.filter(part => part.type === 'day' || part.type === 'month' || part.type === 'year').map(part => part.value).join(' ');
  const utcPart = parts.find(part => part.type === 'timeZoneName').value;
  
  const formattedDate = `${timePart}, ${datePart} (${utcPart})`;
  const res = {
    ipAddress, address, currentTime : formattedDate
  }
  return res;
}

// stripe payment api 
export const createCheckoutSession = async (endpoint) => {
  const { data } = await $authHost.post("api/user/create-checkout-session",{page_slug:endpoint});
  return data;
}
// stripe payment api 
export const createBillingSession = async (stripe_customer_id , endpoint) => {
  const { data } = await $authHost.post("api/user/create-billing-session",{stripe_customer_id:stripe_customer_id, page_slug:endpoint});
  return data;
}


// api to set array of components id 

export const setComponents = async(arrange_components)=>{
  const { data } = await $authHost.post("api/user/setComponents", {arrange_components});
  return data;
}

// api to set image banner 

export const setImageBanner = async(user_banner)=>{
  const { data } = await $authHost.post("api/user/SaveBanner", user_banner);
  return data;
}

// api to delete image banner 

export const DeleteImageBanner = async()=>{
  const { data } = await $authHost.post("api/user/deleteBanner");
  return data;
}

// api to set Text

export const setTextData = async( layer_text, layer_border )=>{
  const { data } = await $authHost.post("api/user/layerText",{layer_text , layer_border});
  return data;
}

export const setImageBannerBorder = async( banner_border )=>{
  const { data } = await $authHost.post("api/user/SaveBorder",{banner_border});
  return data;
}
