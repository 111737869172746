import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  sun,
  dark,
  blank_page_svg,
  searchIcon,
  defaultImage,
} from "../images";
import { Context } from "../index";
import { ThemeContext, themes } from "../store/ThemeContext";
import {
  HOME_ROUTE,
  SEARCH_USER,
  USER_ACCOUNT_ROUTE,
  USER_SETTINGS_ROUTE,
  USER_COMMS_ROUTE
} from "../utils/consts";

import { getInfo, listBookmarks, signOutSession } from "../http/userApi";
import Modal from "react-modal";
import { observer } from "mobx-react-lite";
import AccountsMenu from "../components/AccountsMenu"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    height: "auto",
    border: "0",
  },
};

const RightMenu = observer(() => {
  const { user } = useContext(Context);
  const [isAuthPremium, setIsAuthPremium] = useState(user.user.is_premium);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [userInfo, setUserInfo] = useState(null);

  const [showModalMenu, setShowModalMenu] = useState(false);
  const [showModalAccounts, setShowModalAccounts] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme-color") === "white-content" ? false : true
  );
  useEffect(() => { setIsAuthPremium(user.user.is_premium) }, [user.user.is_premium]);
  useEffect(() => {
    getInfo().then((data) => {
      user.setUser(data);
      setUserInfo(data.username);
      setIsAuthPremium(data.is_premium);
    });
  }, []);

  const logOut = async () => {
    try {
      await signOutSession();
      user.setUser({});
      localStorage.removeItem("token");
      localStorage.removeItem("isPrivate");
      localStorage.removeItem("emailNotification");
      localStorage.removeItem("session_id");
      localStorage.removeItem("MessagingPermission")
      console.log("User logged out successfully");
      window.location.href = HOME_ROUTE;
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const searchUser = (e) => {
    e.preventDefault();
    if (!searchValue) return;

    navigate(SEARCH_USER + searchValue.toLowerCase());
    navigate(0);
  };

  const [bookmarks, setBookmarks] = useState("");
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
  }
  function closeModal() {
    setIsOpen(false);
  }

  const loadBookmarks = async (e) => {
    const data = await listBookmarks()
      .then((data) => {
        setBookmarks(data);
        setIsOpen(true);
      })
      .finally();
  };

  const renderThemeIcon = () => {
    return (
      <ThemeContext.Consumer>
        {({ changeTheme }) => (
          <button
            className="self-end"
            color="link"
            onClick={() => {
              setDarkMode(!darkMode);
              changeTheme(darkMode ? themes.light : themes.dark);
              window.dispatchEvent(new Event("theme-color"));
            }}
          >
            <img
              height={30}
              width={30}
              src={darkMode ? dark : sun}
              className="ml-auto"
              alt="sun"
            />
          </button>
        )}
      </ThemeContext.Consumer>
    );
  };
  const searchBookmark = (data) => {
    if (data && data.length) {
      const filtered = bookmarks.data.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val === "string" && val.includes(data)
        )
      );
      setBookmarks({ data: filtered });
    } else {
      loadBookmarks();
    }
  };

  return (
    <div className="rightMenu">
      {user.isAuth && (
        <div className="hidden lg:flex bg-side dark:bg-[#000] h-full w-[160px]">
          <div className="flex flex-col justify-between">
            <div className="flex flex-wrap space-y-2.5 w-full p-5">
              {userInfo && (
                <>
                  <button
                    className={`hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea4d] dark:active:bg-[#eaeaea4d] dark:text-[#FFFFFF] border border-EA rounded  font-semibold common-button common-button-text ${pathname == USER_ACCOUNT_ROUTE ? "bg-[#EAEAEA99]" : ""
                      }`}
                    onClick={() => navigate(USER_ACCOUNT_ROUTE)}
                  >
                    Layer
                  </button>
                  <button
                    className={`hover:bg-[#f9f9f9]  border border-EA rounded  font-semibold common-button hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea] dark:text-[#FFFFFF] dark:active:bg-[#eaeaea]  common-button-text ${pathname == USER_SETTINGS_ROUTE ? "bg-[#EAEAEA99]" : ""
                      }`}
                    onClick={() => navigate(USER_SETTINGS_ROUTE)}
                  >
                    Control
                  </button>
                  <button
                    className={`hover:bg-[#f9f9f9]  border border-EA rounded  font-semibold common-button hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea] dark:text-[#FFFFFF] dark:active:bg-[#eaeaea]  common-button-text ${pathname == USER_COMMS_ROUTE ? "bg-[#EAEAEA99]" : ""
                      }`}
                    onClick={() => navigate(USER_COMMS_ROUTE)}
                  >
                    Comms
                  </button>
                </>
              )}
              {user.isAuth && isAuthPremium && (
                <div
                  onClick={() => loadBookmarks()}
                  className="flex justify-center items-center mt-20 lg:mt-0 border border-[#CCEDF8] px-2.5 py-[9px] rounded bg-[#eaeaea00] hover:bg-[#E5F6FC] active:bg-[#CCEDF8] active:text-[#00A3DD] focus:bg-[#CCEDF8] focus:text-[#00A3DD] text-[#00A3DD] text-[14px] w-[100%] font-[600] cursor-pointer"
                >
                  <span className=" ">Bookmarks</span>
                </div>
              )}

              <div
                style={{ marginTop: 20, marginBottom: 15 }}
                className="border-y-[0.5px] border-[#EAEAEA]"
              />
              {userInfo && (
                <div className="bg-white rounded p-2.5 w-full dark:bg-[#302F2D]">
                  <div className="flex justify-between">
                    <span >
                                        
                      <img
                        className="cursor-pointer"
                        src={blank_page_svg}
                        alt="Blank page icon"
                      />
                    </span>
                    <div className="h-[30px] w-[30px] border-[1px] border-[#E0DFDE] flex items-center justify-center rounded-[5px]">
                      <img
                        className={
                          user.avatar
                            ? "max-w-full h-full object-cover"
                            : "max-w-full h-auto object-contain rounded-none"
                        }
                        src={user?.avatar || defaultImage}
                        alt=""
                      />
                    </div>
                  </div>
                  <span className="inline-block mt-1 text-base text-[#645F5B] dark:text-[#BCBCBC]">
                    @{user?.user?.username || userInfo}
                  </span>
                </div>
              )}
              <button
                className="border border-[#EAC5C3] rounded  text-[#E1655C] font-semibold hover:bg-[#eac5c326] dark:bg-[#eaeaea00] dark:hover:bg-[#eac5c326] focus:bg-[#EAC5C3] dark:focus:bg-[#EAC5C3] active:bg-[#EAC5C3] dark:active:bg-[#EAC5C3]  common-button common-button-text"
                onClick={logOut}
              >
                Sign out
              </button>

              <div>
                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <>
                    <div
                      className=" w-[500px] max-w-full bg-[#fff] h-[auto] text-[#645F5B] dark:bg-[#252423] dark:text-[#fff] p-[20px]
                     border-[#EAEAEA] border-[1px] radius-[10px] rounded-[10px] cursor-pointer"
                    >
                      <div className="mb-[20px] leading-none  text-lg md:text-xl font-semibold text-[#645F5B] dark:text-white dark:text-[#EAEAEA]">
                        Bookmarks
                      </div>
                      <div className="font-normal text-base  mb-[10px] leading-none text-[#645F5B] dark:text-[#fff] ">
                        Search and access your bookmarks.
                      </div>
                      {bookmarks && (
                        <>
                          <div className="overflow-y-auto h-[228px] mt-[40px] bookmarkModal__scrollList">
                            {bookmarks.data.map((item, i) => (
                              <>
                                <div
                                  className={`relative outline-0  text-base text-[#645F5B] dark:text-white w-full px-[2px] py-[12px] cursor-pointer placeholder-[#fff] border-[#EAEAEA] hover:bg-[#D6D6D633] h-[44px] dark:hover:bg-#D6D6D6 ${i == 0
                                    ? " border-t-[1px]"
                                    : "border-b-[1px]"
                                    } bookmarkModal__item`}
                                >
                                  <a
                                    className="flex justify-between"
                                    href={HOME_ROUTE.concat(item.username)}
                                  >
                                    @{item.username}
                                    <img
                                      src={
                                        item.avatar
                                          ? process.env.REACT_APP_API_URL +
                                          item.avatar
                                          : defaultImage
                                      }
                                      className="cursor-pointer text-[#fff] ml-[10px] w-[25px] h-[25px] absolute right-[10px] top-[8px] object-contain"
                                      alt="avatar"
                                    />
                                  </a>
                                </div>
                              </>
                            ))}
                          </div>
                          <div className="relative border-solid border-t-[1px] border-[#EAEAEA] mt-[20px] mx-[-20px] pt-[20px] px-[20px]">
                            <input
                              type="text"
                              className="bg-[#D6D6D633] focus:border-[#0A85D1]  px-2 text-[#645F5B] dark:text-[#fff] text-base outline-0 text-base  h-[35px] rounded w-full p-[10px] cursor-pointer placeholder-[#645F5B] dark:placeholder-[#fff] border-solid border-[1px] border-[#EAEAEA]"
                              placeholder="Search"
                              onKeyUp={(e) => searchBookmark(e.target.value)}
                              onKeyDown={(e) => searchBookmark(e.target.value)}
                            />
                            <svg
                              className="cursor-pointer dark:text-[#fff] text-[#645F5B] mt-[10px] w-[15px] h-[15px] absolute right-[30px] top-[50%] translate-y-[-50%]"
                              aria-hidden="true"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                              ></path>
                            </svg>
                          </div>
                        </>
                      )}
                      {!bookmarks && (
                        <>
                          <div className="relative px-2 text-[#645F5B] text-base outline-0 text-[14px] dark:text-[#fff] h-[35px] rounded w-full p-[10px] cursor-pointer placeholder-[#fff]  border-[#DAD7D4] hover:bg-[#D6D6D6] h-[44px] mt-[10px] border-y-[1px]">
                            No Bookmarks Yet!
                          </div>
                        </>
                      )}
                    </div>
                  </>
                </Modal>
              </div>
            </div>

            <div className="w-full">
              <div className="p-5">
                <form className="relative block" onSubmit={searchUser}>
                  <input
                    className="placeholder:text-[#B30A8E] bg-transparent block w-full border border-[#B30A8E] rounded-md py-2 pr-9 pl-3 outline-none hover:border-[#B30A8E] focus:border-[#B30A8E] text-xs"
                    placeholder="Search"
                    type="text"
                    name="search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <img
                      className={
                        searchValue?.length ? "rounded" : ""
                      }
                      height={10}
                      width={10}
                      src={searchIcon}
                      alt="searchIcon"
                    />
                  </button>
                </form>
              </div>
              {/* <div className="flex flex-col border-t border-EA px-5 py-5 pb-6 space-y-5">
                {renderThemeIcon()}

                <a href="https://layer.guide/legal" target="_blank" rel="noreferrer">
                  <p className="text-[#645F5B] text-xs text-right cursor-pointer">
                    Privacy and terms
                  </p>
                </a>
                <p className="text-[#645F5B] text-xs font-extralight text-right">
                  © Layer
                </p>
              </div> */}
            </div>
          </div>
        </div>
      )}

      {!user.isAuth && (
        <div className="hidden lg:flex bg-side dark:bg-[#000] h-full w-[160px]">
          <div className="flex flex-col justify-between">
            <div className="flex flex-wrap space-y-2.5 w-full p-5">
              <button
                className={`hover:bg-[#eaeaea4d] dark:hover:bg-[#eaeaea4d] focus:bg-[#eaeaea] dark:focus:bg-[#eaeaea] active:bg-[#eaeaea4d] dark:active:bg-[#eaeaea4d] dark:text-[#FFFFFF] border border-EA rounded  font-semibold common-button common-button-text ${pathname == USER_ACCOUNT_ROUTE ? "bg-[#EAEAEA99]" : ""
                  }`}
                onClick={() => {
                  setShowModalAccounts(true);
                }}
              >
                Enter
              </button>
              <AccountsMenu
                isOpen={showModalAccounts}
                onRequestClose={() => {
                  console.log("onRequestClose");
                  setShowModalAccounts(false);
                }}
                onMenu={() => {
                  setShowModalMenu(true);
                  setTimeout(() => {
                    setShowModalAccounts(false);
                  }, 300);
                }}
              />
            </div>
            <div className="w-full">
              <div className="p-5">

                <form className="relative block" onSubmit={searchUser}>
                  <input
                    className="placeholder:text-[#B30A8E] bg-transparent block w-full border border-[#B30A8E] rounded-md py-2 pr-9 pl-3 focus:outline-none text-xs"
                    placeholder="Search"
                    type="text"
                    name="search"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <img
                      className={
                        searchValue?.length ? " rounded" : ""
                      }
                      height={10}
                      width={10}
                      src={searchIcon}
                      alt="searchIcon"
                    />
                  </button>
                </form>
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default RightMenu;
